<script>
import smoothReflow from 'vue-smooth-reflow'
import { mapActions, mapState } from 'pinia'
import { usePageStore } from '@voix/store/pageStore'

import VoixScroller from '@voix/components/chrome/VoixScroller'
import { useNavigationStore } from '../../store/navigationStore'

import IconDownTriangle from '@/components/icons/IconsDownTriangle'
import IconHamburgerMenu from '@/components/icons/IconsHamburger'
import IconAdd from '@/components/icons/IconsAdd'

export default {
  name: 'Navigation',
  components: {
    VoixScroller,
    IconDownTriangle,
    IconHamburgerMenu,
    IconAdd,
  },

  mixins: [smoothReflow],

  inject: ['accentColor'],

  props: {
    currentResort: {
      type: Object,
      required: true,
    },
    resorts: {
      type: Array,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showCovidInfo: false,
      referenceRect: { x: 0, y: 0, width: 0, height: 0 },
      subMenuActive: false,
      activeMenuItem: { id: null, children: [] },
      submenuTop: 200,
      submenuLeft: 200,
      showingMobileSubmenu: null,
      timeout: null,
      openTopMenuLinks: false,
      openLanguageSelect: false,
      desktopPinned: false,
      showBookingModal: false,
    }
  },
  computed: {
    ...mapState(useNavigationStore, ['mobileMenuOpen', 'breakpoint']),
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },

    faqLink() {
      if (this.currentPage.language_code === 'es')
        return this.currentResort.es_faq_link
      else
        return this.currentResort.faq_link
    },

    submenuStyle() {
      return {
        top: `${this.referenceRect.y}px`,
        left: `${this.referenceRect.x}px`,
      }
    },

    alphabetizedResorts() {
      const resorts = JSON.parse(JSON.stringify(this.resorts))
      return resorts.sort((a, b) => {
        if (a.name < b.name)
          return -1
        if (a.name > b.name)
          return 1
        return 0
      })
    },

    showSubMenu() {
      return this.subMenuActive
        && (
          this.activeMenuItem.children.length > 0
          || this.activeMenuItem.permalink === '#resort-dropdown'
          || this.activeMenuItem.permalink === '/all-inclusive-resorts'
        )
    },
  },
  watch: {
    breakpoint() {
      this.calculateNavigationSpacer()
    },
  },

  mounted() {
    this.$smoothReflow({
      el: this.$refs['navigation-submenu'],
    })
    this.calculateNavigationSpacer()
  },
  methods: {
    ...mapActions(useNavigationStore, ['toggleMobileMenu']),

    requestShowSubmenu(item, el) {
      this.subMenuActive = true
      this.activeMenuItem = item
      this.referenceRect = el.getBoundingClientRect()

      if (this.referenceRect?.y)
        this.referenceRect.y -= 5
    },

    requestHideSubmenu() {
      this.subMenuActive = false
    },

    showMobileSubmenu(key) {
      if (this.showingMobileSubmenu === key)
        this.showingMobileSubmenu = null
      else
        this.showingMobileSubmenu = key
    },

    switchLanguage(url) {
      window.location = url
    },

    calculateNavigationSpacer() {
      const spacer = document.getElementById('desktop-navigation-spacer')
      const nav = document.getElementById('navigation')
      if (spacer && nav)
        spacer.style.height = `${nav.clientHeight}px`
    },
  },
}
</script>

<template>
  <div v-if="currentResort" class="clear-both">
    <!-- Desktop Nav -->
    <div class="hidden md:block">
      <div
        id="navigation"
        class="w-full top-0 left-0 right-0 bg-white"
        :down-tolerance="100"
      >
        <div
          v-cloak
          class="flex justify-center items-center w-full relative"
          style="height: 100px"
        >
          <NuxtLink :to="`/${currentResort.url_partial}`">
            <img
              src="/imgs/footer/10-years-playa.png"
              :alt="`${currentResort.name} Logo`"
              width="250"
              height="72"
            >
          </NuxtLink>

          <div class="absolute right-0 top-0 mt-4 mr-4 flex">
            <div
              v-if="currentResort.show_my_trip_link"
              class="mr-4 cursor-pointer uppercase text-sm"
            >
              <a class="text-grey-dark" href="{{ myTripsLinkUrl(currentResort) }}">{{
                $t("my-trips")
              }}</a>
            </div>

            <div v-if="currentPage.localizations.length > 0" class="cursor-pointer uppercase text-sm px-3">
              <span
                class="dvs-cursor-pointer" @blur="openLanguageSelect = false"
                @click.prevent="openLanguageSelect = !openLanguageSelect"
              >
                {{ $t(currentPage.language_code) }}
                <icons-icon-down-triangle class="inline -mt-1" color="#333" :w="12" :h="12" />
              </span>
              <div v-if="openLanguageSelect" class="fixed py-z bg-grey-lightest z-20 mt-2 mr-2">
                <NuxtLink
                  v-for="localization in currentPage.localizations"
                  :key="localization.path"
                  class="block px-3 py-2 small dvs-cursor-pointer text-xs"
                  :to="localization.path"
                >
                  {{ $t(localization.language_code) }}
                </NuxtLink>
              </div>
            </div>

            <div
              v-if="faqLink"
              class="mr-4 cursor-pointer uppercase text-sm text-grey-dark"
            >
              <NuxtLink
                :class="[topBarTextClass]"
                :to="faqLink"
              >
                FAQ
              </NuxtLink>
            </div>
          </div>
        </div>

        <div
          v-if="currentResort.menu !== null"
          class="relative z-60 flex items-center justify-left lg:justify-center border-t border-b bg-white border-grey-lightest w-full px-4"
          @mouseenter="subMenuActive = true"
          @mouseleave="requestHideSubmenu"
        >
          <a name="resort-dropdown" />

          <!-- Main Menu -->
          <ul
            v-cloak
            v-if="currentResort?.menu" class="flex uppercase my-4 space-x-4 lg:space-x-4"
          >
            <li
              v-for="(item, menuKey) in currentResort.menu.menu_items"
              :key="menuKey"
              @mouseenter="requestShowSubmenu(item, $event.target)"
              @mouseleave="requestHideSubmenu"
            >
              <NuxtLink :to="item.permalink" class="text-grey-dark">
                {{ item.name }}
                <IconDownTriangle
                  v-if="
                    item.children.length > 0
                      || item.name === 'Our Resorts'
                      || item.name === 'resort'
                  "
                  class="inline -mt-1"
                  color="#333"
                  :w="12"
                  :h="12"
                />
              </NuxtLink>
              <div v-if="item.children.length > 0" class="submenu hidden">
                <div class="text-3xl uppercase title font-thin mb-4 text-center">
                  {{ item.name }}
                </div>
                <ul class="text-center">
                  <li
                    v-for="(menuItem, itemKey) in item.children"
                    :key="itemKey"
                    class="mb-2"
                  >
                    <NuxtLink :to="menuItem.permalink" class="text-grey-dark mx-4">
                      {{
                        menuItem.name
                      }}
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div
            class="absolute top-0 bottom-0 right-0 flex items-stretch"
          >
            <button class="btn" @click="showBookingModal = !showBookingModal">
              {{ $t("book-now") }}
            </button>
          </div>
        </div>

        <!-- v-show="subMenuActive" -->
        <div
          class="fixed z-50 mt-6 pt-6 transition-all duration-250 ease-out ml-10 -translate-x-[50%]"
          :class="{ 'opacity-0 pointer-events-none': !showSubMenu, 'opacity-100 pointer-events-auto': showSubMenu }"
          :style="submenuStyle"
          @mouseenter="subMenuActive = true"
          @mouseleave="subMenuActive = false"
        >
          <div
            v-if="currentResort?.menu"
            ref="navigation-submenu"
            class="bg-white shadow rounded p-8 mt-2 submenu"
            style="min-width: 300px; transition: height 1s"
          >
            <div
              v-for="(item, menuKey) in currentResort.menu.menu_items"
              :key="menuKey"
            >
              <div v-if="item.permalink === '#resort-dropdown'" :class="{ hidden: activeMenuItem.id !== item.id }">
                <div style="min-width: 730px">
                  <div class="text-3xl uppercase title font-thin mb-4 text-center">
                    Our Resorts
                  </div>
                  <div class="flex justify-center">
                    <div class="mx-8 w-1/2 text-right">
                      <h6 class="font-light mb-4">
                        Dominican Republic
                      </h6>
                      <ul>
                        <template v-for="(resort, key) in resorts" :key="key">
                          <template v-if="resort.country === 'Dominican Republic'">
                            <li class="mb-2">
                              <a
                                class="text-grey-dark"
                                :href="resort.permalink"
                              >
                                {{ resort.name }}
                              </a>
                            </li>
                          </template>
                        </template>
                      </ul>
                      <h6 class="mt-8 font-light mb-4">
                        Jamaica
                      </h6>
                      <ul>
                        <template v-for="(resort, key) in resorts" :key="key">
                          <template v-if="resort.country === 'Jamaica'">
                            <li class="mb-2">
                              <a
                                class="text-grey-dark"
                                :href="resort.permalink"
                              >
                                {{ resort.name }}
                              </a>
                            </li>
                          </template>
                        </template>
                      </ul>
                    </div>
                    <div class="mx-8 text-left w-1/2">
                      <h6 class="font-light mb-4">
                        Mexico
                      </h6>
                      <ul>
                        <template v-for="(resort, key) in resorts" :key="key">
                          <template v-if="resort.country === 'Mexico'">
                            <li class="mb-2">
                              <a
                                class="text-grey-dark"
                                :href="resort.permalink"
                              >
                                {{ resort.name }}
                              </a>
                            </li>
                          </template>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.permalink === '/all-inclusive-resorts'" :class="{ hidden: activeMenuItem.id !== item.id }">
                <div style="width: 785px">
                  <div class="text-3xl uppercase title font-thin mb-4 text-center">
                    Our Brands
                  </div>
                  <div class="flex justify-start items-center flex-wrap w-full">
                    <div class="w-1/3 my-4 px-4">
                      <a
                        href="https://www.resortsbyhyatt.com/"
                        class="zoom-emphasize"
                        aria-label="Hyatt Ziva"
                      >
                        <LazyLogosHyattZiva :w="190" :h="100" />
                      </a>
                    </div>
                    <div class="w-1/3 my-4 px-4">
                      <a
                        href="https://www.resortsbyhyatt.com/"
                        class="zoom-emphasize"
                        aria-label="Hyatt Zilara"
                      >
                        <LazyLogosHyattZilara :w="190" :h="100" />
                      </a>
                    </div>
                    <div class="w-1/3 my-4 px-4">
                      <a
                        href="https://www.playaresorts.com/turquoize-ziva-cancun/"
                        class="zoom-emphasize"
                        aria-label="Turquoize"
                      >
                        <LazyLogosTurquoize :w="190" :h="100" />
                      </a>
                    </div>
                    <div class="w-1/3 my-4 px-4">
                      <a
                        href="https://hiltonbyplaya.com/"
                        class="zoom-emphasize"
                        aria-label="Hilton"
                      >
                        <LazyLogosHilton :w="190" :h="100" />
                      </a>
                    </div>
                    <div class="w-1/3 my-4 px-4">
                      <a
                        href="https://sanctuarycapcana.com/"
                        class="zoom-emphasize"
                        aria-label="Sanctuary"
                      >
                        <LazyLogosSanctuary :w="190" :h="100" />
                      </a>
                    </div>
                    <div class="w-1/3 my-4 px-4">
                      <a
                        href="https://www.jewelgrande.com/"
                        class="zoom-emphasize"
                        aria-label="Jewel Grande"
                      >
                        <LazyLogosJewelGrande :w="190" :h="100" />
                      </a>
                    </div>
                    <div class="w-1/3 my-4 px-4">
                      <a
                        href="https://alltrabyplaya.com/"
                        class="zoom-emphasize"
                        style="color: #55565a"
                        aria-label="Wyndham Alltra"
                      >
                        <LazyLogosWyndham class="w-48" style="height: 96px" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.children.length > 0" :class="{ hidden: activeMenuItem.id !== item.id }">
                <div class="flex justify-between items-center flex-wrap w-full">
                  <div class="text-center w-full">
                    <div class="text-3xl uppercase title font-thin mb-4 text-center">
                      {{ item.name }}
                    </div>
                    <ul>
                      <template
                        v-for="(child, childKey) in item.children"
                        :key="childKey"
                      >
                        <li class="mb-2">
                          <NuxtLink class="text-grey-dark" :to="child.permalink">
                            {{ child.name }}
                          </NuxtLink>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showBookingModal"
        class="relative px-8 z-50 flex justify-center inline-flight-booking w-full bg-black"
      >
        <button class="absolute top-0 right-0 text-xs uppercase m-4 text-gray-200" @click="showBookingModal = !showBookingModal">
          Close
        </button>
        <FlightBookingForm />
      </div>
    </div>

    <!-- Mobile Nav -->
    <div class="md:hidden fixed bg-white top-0 left-0 right-0 z-50">
      <div
        class="flex md:flex lg:flex justify-between items-stretch border-b-grey border-b relative transition-all duration-500 ease-in-out"
        :class="{ 'translate-x-[80%]': mobileMenuOpen }"
      >
        <div class="text-grey-darker py-8 pl-4" @click="toggleMobileMenu">
          <IconHamburgerMenu :w="25" :h="25" :fill="true" />
        </div>
        <div class="mx-4">
          <a href="/">
            <img
              src="https://dj88jfzmsobor.cloudfront.net/media/logo-playa-hotels-and-resorts.png"
              :alt="`${currentResort.name} Logo`"
              class="mt-6"
              width="97"
              height="50"
            >
          </a>
        </div>
        <div class="flex items-stretch">
          <button class="btn btn-sm bg-playa-action" @click="showBookingModal = !showBookingModal">
            {{ $t("book-now") }}
          </button>
        </div>

        <div v-if="showBookingModal" class="fixed inset-0 lg:relative px-8 z-0 flex justify-center inline-flight-booking bg-black">
          <div class="relative z-10 w-full">
            <FlightBookingForm class="mt-12 lg:mt-0" />
          </div>
          <button class="p-4 absolute z-20 top-0 right-0 m-4 pointer-events-auto" @click="showBookingModal = !showBookingModal">
            <Icon name="heroicons:x-mark-20-solid" class="text-white w-12 h-12" />
          </button>
        </div>
      </div>

      <ClientOnly>
        <Teleport to="#portal-mobile-menu">
          <div
            class="fixed top-0 bottom-0 left-0 w-4/5 bg-playa-inverse transition-all duration-500 ease-in-out"
            style="z-index: 99999"
            :class="{ '-translate-x-full opacity-0': !mobileMenuOpen }"
          >
            <VoixScroller>
              <div class="flex w-full">
                <button
                  class="py-3 bg-playa-action text-playa-inverse font-bold uppercase w-full"
                  @click="showBookingModal = !showBookingModal"
                >
                  {{ $t("book-now") }}
                </button>
              </div>

              <ul v-if="currentResort?.menu" class="m-8 text-playa-action">
                <li
                  v-for="(item, key) in currentResort.menu.menu_items"
                  :key="key"
                  class="my-4 pl-8"
                >
                  <div class="flex items-center" @click="showMobileSubmenu(key)">
                    <IconAdd
                      v-if="
                        item.permalink === '#resort-dropdown'
                          || (item.children && item.children.length > 0)
                      "
                      style="margin-left: -25px"
                      :w="25"
                      :h="25"
                      :fill="true"
                    />
                    <a
                      :href="item.permalink"
                      class="text-playa-action font-bold text-2xl"
                    >{{ item.name }}</a>
                  </div>
                  <div v-if="item.children.length > 0 && showingMobileSubmenu === key">
                    <ul class="mt-4">
                      <li
                        v-for="(menuItem, itemKey) in item.children"
                        :key="itemKey"
                        class="mb-2"
                      >
                        <a v-if="item.permalink !== '#resort-dropdown'" :href="menuItem.permalink" class="text-playa-action">{{
                          menuItem.name
                        }}</a>
                        <span v-else class="text-playa-action">{{
                          menuItem.name
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="item.permalink === '#resort-dropdown' && showingMobileSubmenu === key"
                    class="pr-8"
                  >
                    <div class="flex flex-col mb-8 mt-4">
                      <div class="mb-8">
                        <h4 class="font-bold mb-4 text-left uppercase">
                          Mexico
                        </h4>
                        <ul>
                          <template
                            v-for="(resort, resortsKey) in alphabetizedResorts"
                          >
                            <template v-if="resort.country === 'Mexico'">
                              <li :key="resortsKey" class="mb-2">
                                <a
                                  class="text-playa-action"
                                  :href="resort.permalink"
                                >
                                  {{ resort.name }}
                                </a>
                              </li>
                            </template>
                          </template>
                        </ul>
                      </div>
                      <div class="mb-8">
                        <h4 class="font-bold mb-4 text-left uppercase">
                          Jamaica
                        </h4>
                        <ul>
                          <template
                            v-for="(resort, resortsKey) in alphabetizedResorts"
                          >
                            <template v-if="resort.country === 'Jamaica'">
                              <li :key="resortsKey" class="mb-2">
                                <a
                                  class="text-playa-action"
                                  :href="resort.permalink"
                                >
                                  {{ resort.name }}
                                </a>
                              </li>
                            </template>
                          </template>
                        </ul>
                      </div>
                      <div class="mb-8">
                        <h4 class="font-bold mb-4 text-left uppercase">
                          Dominican Republic
                        </h4>
                        <ul>
                          <template
                            v-for="(resort, resortsKey) in alphabetizedResorts"
                          >
                            <template v-if="resort.country === 'Dominican Republic'">
                              <li :key="resortsKey" class="mb-2">
                                <a
                                  class="text-playa-action"
                                  :href="resort.permalink"
                                >
                                  {{ resort.name }}
                                </a>
                              </li>
                            </template>
                          </template>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </VoixScroller>
          </div>
        </Teleport>
      </ClientOnly>
    </div>
    <div style="height: 120px" class="md:hidden" />
  </div>
</template>
